export const plansState = {
  name: 'plans',
  url: '/plans',
  component: 'plans',
  resolve: {
    avtos: (avtoServiceApiV2) =>
      avtoServiceApiV2.get({}).then((resp) => resp.results.idlize()),
    ourFirms: (firmServiceApiV2) =>
      firmServiceApiV2
        .getOurFirms()
        .then((resp) => resp.results.idlize((f) => f.kmbm)),
    ekspeditors: (ekspServiceApiV2) =>
      ekspServiceApiV2.get({}).then((resp) => resp.results.idlize()),
  },
  data: {
    title: 'Планирование перевозок',
  },
}

export const plansPricepUsageState = {
  name: 'plansPricepUsage',
  url: '/plans/pricep-usage',
  component: 'pricepUsageForm',
  resolve: {},
  data: {
    title: 'Использование прицепов',
  },
}
