import { FormController } from '../../controllers/form-controller.class'

class PlanFormController extends FormController {
  /** @ngInject */
  constructor(
    $q,
    $scope,
    $injector,
    popup,
    planServiceApiV2,
    contractServiceApiV2,
    putlistServiceApiV2
  ) {
    super($scope)
    this.$q = $q
    this.popup = popup
    this.api = planServiceApiV2
    this.contractServiceApiV2 = contractServiceApiV2
    this.putlistServiceApiV2 = putlistServiceApiV2
  }

  /** Инициализация компонента. */
  $onInit() {
    if (this.model.truck_drivers.length === 0) {
      this.model.truck_drivers = [
        { truck: this.model.avto, driver: this.model.driver },
      ]
    }
    if (this.model.priceps.length === 0) {
      this.model.priceps = [{ pricep: this.model.pricep }]
    }
    this.reloadContracts().then((contracts) => {
      this.contracts = contracts
    })
  }

  /**
   * В перевозке существуют мобилизованные прицепы.
   * @returns {boolean}
   * */
  get planHasMobilizedPriceps() {
    return this.model.priceps.find((p) => p.mobilized) !== undefined
  }

  /** Выбранный тягач. */
  get avto() {
    const truck = this.model.truck_drivers[0].truck
    return truck ? this.avtos[truck] : null
  }

  /** Фирма-оператор заявки. */
  get operator() {
    return this.ourFirms[this.zay.type]
  }

  /** Фирма-перевозчик. */
  get server() {
    return this.avto?.firm_info
  }

  /**
   * Фирма-заказчик по заявке.
   *
   * Если исполнитель (фирма-перевозчик) является оператором заявки (т.е. если
   * оператор заявки сам и везет груз, а не перекладывает его на др. фирму), то
   * клиент - это внешняя фирма, указанная в заявке.
   *
   * В случае, если оператор заявки договаривается с др. фирмой, то в рамках
   * данной перевозки он сам выступает клиентом, а фирма-субподрядчик -
   * исполнителем.
   *
   * По умолчанию, если не удается определить исполнителя
   * */
  get client() {
    if (this.server) {
      return this.server.id === this.operator.id
        ? this.zayClient
        : this.operator
    } else {
      return this.zayClient
    }
  }

  /** Вес автопоезда. */
  get totalWeight() {
    let totalWeight = 0.0

    if (this.avto) {
      totalWeight += nan2zero(parseFloat(this.avto.weight))
    }

    if (this.pricep) {
      totalWeight += nan2zero(parseFloat(this.pricep.weight))
    }

    if (this.model) {
      totalWeight +=
        nan2zero(parseFloat(this.model.weight)) +
        nan2zero(parseFloat(this.model.addw))
    }

    return totalWeight

    function nan2zero(v) {
      return isNaN(v) ? 0.0 : v
    }
  }

  addTruck() {
    this.model.truck_drivers.push({ truck: null, driver: null })
  }

  removeTruck(truckDriver) {
    const idx = this.model.truck_drivers.indexOf(truckDriver)
    if (idx > -1) {
      this.model.truck_drivers.splice(idx, 1)
    }
  }

  addPricep() {
    this.model.priceps.push({ pricep: null })
  }

  removePricep(pricep) {
    const idx = this.model.priceps.indexOf(pricep)
    if (idx > -1) {
      this.model.priceps.splice(idx, 1)
    }
  }

  /** Обновление списка контрактов для текущих перевозчика и заказчика. */
  reloadContracts() {
    const deferred = this.$q.defer()
    if (this.server && this.client) {
      const client = this.client.id
      const server = this.server.id
      this.contractServiceApiV2.get({ client, server }).then((resp) => {
        deferred.resolve(resp.results)
      })
    } else {
      deferred.resolve([])
    }
    return deferred.promise
  }

  /** Обработка изменения значения тягача. */
  onAvtoChange() {
    this.reloadContracts().then((contracts) => {
      this.contracts = contracts
    })
  }

  /** Печать ТТН. */
  printTTN() {
    print('ttn.php', {
      id: this.model.id,
      _fname: `ТТН ${this.model.pid}/${this.model.nomer}`,
    })
  }

  /** Печать "Задания". */
  printPlanZadan() {
    // eslint-disable-next-line no-undef
    printUrl('print2.php?path=plan_zadan.php&type=1&id=' + this.model.id, '')
  }

  /** Печать "Разрешения". */
  printPlanRazresh() {
    // eslint-disable-next-line no-undef
    printUrl('print2.php?path=plan_zadan.php&type=2&id=' + this.model.id, '')
  }

  /** Открыть заявку. */
  openZay() {
    this.popup.state('zay.base.detail', { id: this.model.pid }, 970, 770)
  }

  /** Извлечь из путевого листа. */
  extractFromPlist() {
    this.putlistServiceApiV2.get({ id: this.model.plist }).then((putlist) => {
      if (putlist.avto) this.model.truck_drivers[0].truck = putlist.avto
      if (putlist.driver1) this.model.truck_drivers[0].driver = putlist.driver1
      if (putlist.pricep) this.model.priceps[0].pricep = putlist.pricep
    })
  }

  /** Вставить план из буфера */
  pastePlan() {
    Object.assign(this.model, this.copiedPlan)
  }
}

export const PlanFormComponent = {
  template: require('./plan-form.component.html'),
  styles: [require('./plan-form.component.scss')],
  bindings: {
    model: '<',
    zay: '<',
    zayClient: '<',
    avtos: '<',
    ourFirms: '<',
    copiedPlan: '<',
    onSave: '&',
    onClose: '&',
  },
  controller: PlanFormController,
}
