import PopeyeModal from '../popeye-modal.class'

class PlanModalController {
  static $inject = [
    '$scope',
    'plan',
    'zay',
    'zayClient',
    'avtos',
    'ourFirms',
    'copiedPlan',
  ]

  constructor($scope, plan, zay, zayClient, avtos, ourFirms, copiedPlan) {
    this.$scope = $scope
    this.plan = plan
    this.zay = zay
    this.zayClient = zayClient
    this.avtos = avtos
    this.ourFirms = ourFirms
    this.copiedPlan = copiedPlan
    $scope.vm = this
  }
}

export class PlanModal extends PopeyeModal {
  modal = {
    template: require('./plan.modal.html'),
    controller: PlanModalController,
  }
}
